main{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    
}


@media only screen and (max-width: 480px){
    main{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px;
        
    }
}