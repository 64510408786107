.com{
    color: rgb(255, 255, 255);
    background-color: #2D384A;
    text-align: center;
    font-size: 28px;
    line-height: 40px;
    padding: 20px 70px;
    border-radius: 15px;
    h4{
        color: #FF4C2B;
    }
    .photo{
        background-image: url('../../assets/photo/ph.JPG');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 1090px;
    }
}

@media only screen and (max-width: 480px){
    .com{
        color: rgb(255, 255, 255);
        background-color: #2D384A;
        text-align: center;
        font-size: 20px;
        padding: 10px 20px;
        border-radius: 15px;
        line-height: 27px;
        h4{
            color: #FF4C2B;
        }
        .photo{
            width: 100%;
            height: 310px;
        }
    }
}