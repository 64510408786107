.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-bottom: 50px;
    background-color: #344756;
    overflow: hidden;
    text-align: center;
    padding: 10px;
    font-size: 25px;
    font-weight: 100px;
    transition: transform 1s ease;
    border-radius: 10px;
    border: 1px solid #f1f1f1; 
    

        &:hover{
            border-radius: 10px;
            border: 1px solid #f1f1f1; 
            transform: scale(1.1);

            
        }
}

@media only screen and (max-width: 480px){
    .item{
        display: flex;
        flex-direction: column;
        justify-content: center; 
        width: 100%;
        margin-bottom: 20px;
        background-color: #344756;
        overflow: hidden;
        text-align: center;
        padding: 5px;
        font-size: 18px;
        font-weight: 100px;
        transition: transform 1s ease;
        border-radius: 10px;
        border: 1px solid #f1f1f1; 
        
    
            &:hover{
                border-radius: 10px;
                border: 1px solid #f1f1f1; 
                transform: scale(1.1);
    
                
            }
    } 
}

