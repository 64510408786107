.fullItem{
    position: fixed;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(46, 21, 21, 0.5);
    z-index: 9999;
    overflow: hidden;


    h1,h2,p,b{
        margin: 0 20px;
        color: #fff;
        padding-bottom: 10px;
        font-size: 25px;
    }

    b{
        color:#FF4C2B;
        font-size: 25px;
    }

    .div{
        width: 1000px;
        position: relative;
        margin: 10% auto;
        background: #344756;
        border-radius: 15px;

        .but{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 5px 5px;
        }


        button{
            position: relative;
            bottom: 15px;
            left: 15px;
            height: 35px;
            width: 35px;
            border-radius: 38px;
            background-color: #FF4C2B;
            color: white;
            font-size: 20px;
            font-weight: 700;
            transition: transform 1ms ease;
    

        &:hover{

            border: 1px solid #f1f1f1; 
            border-color: rgb(255, 255, 255);
            transform: scale(1.1);
            
        }
            
        }
    }

}

@media only screen and (max-width: 480px){
    .fullItem{
        position: fixed;
        top: -50px;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(46, 21, 21, 0.5);
        z-index: 9999;
        overflow: auto;

    
    
        h1,h2,p,b{
            margin: 0 20px;
            color: #fff;
            padding-bottom: 8px;
            font-size: 18px;
        }
    
        b{
            position: relative;
            left: 20px;
            color:#FF4C2B;
            font-size: 26px;
            

        }
    
        .div{
            width: 350px;
            position: relative;
            margin: 30% auto;
            background: #344756;
            border-radius: 15px;
    
            .but{
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: 5px 5px;
            }
    
    
            button{
                position: relative;
                bottom: 15px;
                left: 15px;
                height: 45px;
                width: 45px;
                border-radius: 38px;
                background-color: #FF4C2B;
                color: white;
                font-size: 30px;
                font-weight: 100;
                transition: transform 1ms ease;
        
    
            &:hover{
    
                border: 1px solid #f1f1f1; 
                border-color: rgb(255, 255, 255);
                transform: scale(1.1);
                
            }
                
            }
        }
    
    }
}