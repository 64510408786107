.categories{
    text-align: center;


    div{
        display: inline-block;
        background: #FF4C2B;
        border-radius: 20px;
        padding: 10px 20px;
        margin-bottom: 25px;
        margin-right: 15px;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all 500ms ease;
        font-size: 18px;
        font-weight: 500;

        &:hover{
            transform: scale(1.1);
        }
    }
}

@media only screen and (max-width: 480px){
    .categories{
        margin-bottom: 20px;
        text-align: center;
    
    
        div{
            background: #FF4C2B;
            border-radius: 20px;
            padding: 5px 10px;
            margin-bottom: 10px;
            margin-right: 10px;
            cursor: pointer;
            border: 1px solid transparent;
            transition: all 500ms ease;
            font-size: 16px;
            font-weight: 500;
    
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}