footer{
    text-align: center;
    margin-top: 100px;
}

@media only screen and (max-width: 480px){
    footer{
        text-align: center;
        margin-top: 20px;
    }
}