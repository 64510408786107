*{
  margin: 0;
  padding: 0;
}

body{
  background-color: #0A1F32;
  color: white;
  font-family: "PT Sans", sans-serif;
  font-weight: 300;
}

.wrapper{
  width: 1250px;
  margin: 50px auto;
}

/* Медиа-запросы */
@media only screen and (max-width: 1280px) {
  /* Стили для экранов шириной до 1280px */
  .wrapper {
  width: 900px;
  margin: 40px auto;
  }
 }
 
 @media only screen and (max-width: 1024px) {
  /* Стили для экранов шириной до 1024px */
  .wrapper {
  width: 700px;
  margin: 30px auto;
  }
 }
 
 @media only screen and (max-width: 768px) {
  /* Стили для экранов шириной до 768px */
  .wrapper {
  width: 600px;
  margin: 20px auto;
  }
 }
 
 @media only screen and (max-width: 480px) {
  /* Стили для экранов шириной до 480px */
  .wrapper {
  width: 350px;
  margin: 10px 30px;
  }
 }